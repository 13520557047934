<template>
    <div class="container-fluid px-0">
        <ol class="page-breadcrumb breadcrumb">
            <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Services</li>
        </ol>
        <div class="row">
            <div class="col">
                <div class="card card-rounded-lg">
                    <div class="card-body">
                        <h6 class="card-title">My Services</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error mollitia necessitatibus nobis porro quae totam. Aperiam cum doloremque natus soluta? Autem fuga laborum libero nisi quidem reiciendis tempora unde voluptatem!</p>
                        <div class="row mt-4">
                            <div class="col-12 col-lg-6" v-for="(service, index) in services" :key="index">
                                <profile-item
                                    :title="service.title"
                                    :text="service.text"
                                    type="service"
                                    control-text="Remove"
                                />
                            </div>
                            <div class="col-12 col-lg-6">
                                <profile-item type="add" title="Add More Services" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr," />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProfileItem from "../../components/ProfileItem";

    export default {
        name: 'Services',
        components: {
            ProfileItem
        },
        data() {
            return {
                services: [
                    {title: 'Document & Packages', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,'},
                    {title: 'Medical Courier', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,'},
                    {title: 'Food and Perishables', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,'}
                ]
            }
        }
    }
</script>
